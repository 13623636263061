// =================================================
// Header LESS
// =================================================
// Header Fixed
// -------------------------------------------------
@mixin header-fixed() {
  position: relative;
  width: 100%;
  // background-color: transparent;
  background-color: $primary;
  color: $text-primary;
  z-index: 999;
}

// Header Wrapper Fixed
// -------------------------------------------------
@mixin header-wrapper-fixed() {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  box-sizing: border-box;
}

// Header Logo Fixed
// -------------------------------------------------
@mixin header-logo-fixed() {
  span {
    font-size: 36px;
    font-weight: 600;
    color: $global-text-color;
  }
}

// Header Nav Fixed
// -------------------------------------------------
@mixin header-nav-fixed() {
  display: none;
  flex-direction: row;
  align-items: center;

  @media (min-width: $screen-medium) {
    display: flex;
  }

  & > li {
    list-style: none;

    & > a {
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: stretch;
      position: relative;
      height: 80px;
      padding: 0 20px;
      color: inherit;
      font-size: 18px;
      font-weight: 600;
      text-transform: uppercase;

      &:hover {
        color: #ffffff !important;
        background-color: $dark;
      }
    }
  }
}

// Header Icon Nav Fixed
// -------------------------------------------------
@mixin header-icon-nav-fixed() {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    width: 80px;
    background-color: transparent;
    border: 0 none;
    border-radius: 0;

    @media (max-width: $screen-medium-max) {
      width: 60px;
      margin-right: -15px;
    }

    &-light {
      color: white;
      background-color: $dark;
      font-size: 18px;

      .closed {
        display: block;
        font-size: 26px;
      }

      .see {
        display: none;
      }

      // collapsed
      &.collapsed {
        color: $dark;
        background-color: transparent;

        .closed {
          display: none;
        }

        .see {
          display: block;
        }

        &:hover {
          color: $white;
          background-color: $dark;
        }
      }
    }

    &-toggle {
      @media (max-width: $screen-medium-max) {
        display: flex;
      }
      @media (min-width: $screen-medium) {
        display: none;
      }
    }
  }
}

// Header Box Fixed
// -------------------------------------------------
@mixin header-box-fixed() {
  position: absolute;
  top: 80px;
  left: 0;
  right: 0;
  width: 100%;
}

// Header Box Fixed
// -------------------------------------------------
@mixin header-box-body-fixed() {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: $white;
  height: 400px;
  width: 100%;
  padding: 42px 0;

  @media (max-width: $screen-large-max) {
    height: 100%;
  }

  // form control
  .form-control {
    height: 85px !important;
    font-weight: 800;
    font-size: 50px;
    width: 100%;

    @media (min-width: $screen-medium) {
      width: 70%;
    }

    @media (max-width: $screen-medium-max) {
      font-size: 30px;
    }

    &.max-width {
      @media (min-width: $screen-medium) {
        max-width: 651px;
      }
    }
  }

  // form label
  .form-label {
    font-size: 20px;
  }

  // btn light
  .btn-light {
    background-color: transparent;
    border: 0 none;
    font-weight: 500;
    position: relative;

    &:hover {
      opacity: 0.8;
    }
  }
}

// Header Register Nav
// -------------------------------------------------
@mixin header-btn-login-fixed() {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  i {
    margin-left: 8px;
  }

  @media (max-width: $screen-medium-max) {
    text-align: left;
    justify-content: flex-start;
    padding: 16px 0;
  }
}

// Header Register Nav
// -------------------------------------------------
@mixin header-register-nav-fixed() {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  height: 100%;
  width: 100%;

  @media (max-width: $screen-medium-max) {
    align-items: flex-end;
  }

  .btn-light {
    display: flex;
    align-items: center;
    padding: 16px 0;

    @media (max-width: $screen-medium-max) {
      text-align: right;
      i {
        display: none;
      }
    }

    .btn-text {
      display: flex;
      flex-direction: column;
      margin-right: 8px;

      small {
        color: $global-text-color-light;
        font-size: 12px;
      }
    }
  }
}

// Header Account Nav
// -------------------------------------------------
@mixin header-account-nav-fixed() {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  &-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    &--title {
      font-size: 20px;
      font-weight: 600;
      text-transform: uppercase;
    }

    &--detail {
      font-size: 16px;
    }
  }
  &-body {
    position: relative;
    margin-top: 26px;

    li {
      a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        padding: 13px 0;
        font-size: 20px;
        font-weight: 600;
        max-width: calc(100% - 48%);

        span {
          position: relative;

          &:before {
            content: "";
            position: absolute;
            bottom: -2px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: $dark;
            opacity: 0;
            visibility: hidden;
          }
        }

        &:hover {
          i {
            color: $white !important;
            background-color: $dark !important;
          }
          span {
            &:before {
              opacity: 1;
              visibility: visible;
            }
          }
        }

        i {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          margin-left: 16px;
          width: 27px;
          height: 27px;
          background-color: $white;
          border: 1px solid $dark;
          color: $dark;
          border-radius: 100%;
        }
      }
    }
  }
}

// Header Control
// -------------------------------------------------
.header {
  @include header-fixed;
  // Wrapper
  &-wrapper {
    @include header-wrapper-fixed;
  }

  // Logo
  &-logo {
    @include header-logo-fixed;
  }

  // Nav
  &-nav {
    @include header-nav-fixed;
  }

  // Icon nav
  &-icon-nav {
    @include header-icon-nav-fixed;
  }

  // Box
  &-box {
    @include header-box-fixed;

    // body
    &-body {
      @include header-box-body-fixed;

      .mobile-menu-product-title {
        text-transform: uppercase;
        font-family: $roboto-bold;
        line-height: 26px;
      }
    }

    // register nav
    .register-nav {
      @include header-register-nav-fixed;
    }

    // btn login
    .btn-login {
      @include header-btn-login-fixed;
    }

    // account nav
    .account-nav {
      @include header-account-nav-fixed;
    }
  }
}
