// =================================================
// TEXT BOX LESS
// =================================================
// Text box control
// -------------------------------------------------
.text-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (max-width: $screen-medium-max) {
    margin-bottom: 16px;
  }
  &:hover {
    .text-box-title {
      &:before {
        width: 100%;
      }
    }
  }
  &-title {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    width: 100%;
    text-align: center;

    h2 {
      font-size: 18px;
      font-weight: 300;
      text-transform: uppercase;
      color: $global-text-color;

      @media (min-width: $screen-medium) {
        max-width: 60%;
      }
    }

    &:before {
      content: "";
      position: absolute;
      top: -16px;
      height: 2px;
      width: 0;
      background-color: $dark;
      transition: width 0.3s ease;
    }
  }

  &-content {
    position: relative;
    margin-top: 10px;

    p {
      font-weight: 300;
      color: $global-text-color;
      font-size: $global-font-size;
    }
  }
}

.img-text-box {
  &-figure {
    position: relative;
    width: 100%;

    img {
      width: 100%;
    }

    .figure-2 {
      @media (min-width: $screen-medium) {
        margin-top: -70px;
        margin-left: -70px;
      }
      @media (max-width: $screen-medium-max) {
        margin-top: 18px;
      }
    }
  }

  &-content {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;

    h5,
    p {
      color: $global-text-color;
    }

    h5 {
      font-size: 18px;
      font-weight: bold;
      text-transform: uppercase;

      @media (max-width: $screen-medium-max) {
        margin-top: 18px;
      }
    }

    p {
      margin-top: 18px;
      font-weight: 300;
      font-size: $global-font-size;
    }
  }
}

.info-text {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  position: relative;

  @media (min-width: $screen-medium) {
    margin: 0 auto;
    width: 80%;
  }

  &-title {
    position: relative;

    h5 {
      color: $global-text-color;
      font-size: 18px;
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  &-content {
    p {
      font-size: $global-font-size;
      font-weight: 300;
      color: $global-text-color;
    }
  }
}
.text-block-w {
  @media (min-width: $screen-medium) {
    margin: 0 auto;
    width: 80%;
  }
}
