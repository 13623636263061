// =================================================
// BUTTON LESS
// =================================================
// Button control
// -------------------------------------------------
.btn {
  border: 0 none;
  border-radius: 0;
  padding: 11.5px 40px;
  text-align: left;

  &:focus {
    box-shadow: none;
  }

  &-icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding: 13px 0;
    font-size: 20px;
    font-weight: 600;
    max-width: calc(100% - 48%);

    span {
      position: relative;

      &:before {
        content: "";
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: $dark;
        opacity: 0;
        visibility: hidden;
      }
    }

    &:hover {
      i {
        color: $white !important;
        background-color: $dark !important;
      }

      span {
        &:before {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    i {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      margin-left: 16px;
      width: 27px;
      height: 27px;
      background-color: $white;
      border: 1px solid $dark;
      color: $dark;
      border-radius: 100%;
    }
  }

  // faded
  &-faded {
    background-color: $faded;
    border-color: $faded;
  }

  // faded
  &-muted {
    color: #ffffff;
    font-weight: 600;
    background-color: #cbcbcb;
    border-color: #cbcbcb;

    &:hover {
      color: #ffffff;
      background-color: darken(#cbcbcb, 5%);
    }
  }

  // dark
  &-dark {
    font-weight: 600;
    background-color: #000000;
    border-color: #000000;
  }

  // outline
  &-outline {
    &-dark {
      border: 1px solid $dark;
      color: $global-text-color;
      padding: 5px 46px;
      &:hover {
        background-color: $dark;
      }
    }
  }

  // next
  &-set {
    text-align: center;
    width: 253px;
    @media (max-width: $screen-small-max) {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
  }

  &-secondary-custom {
    background-color: $secondary;
    color: $text-secondary;
  }

  &-primary-custom {
    background-color: $primary;
    color: $text-primary;
  }
}

.custom-button {
  border: 1px solid #000000;
  border-radius: 0;
  color: #000000;
  text-align: center;
  padding: 8px 20px;
  width: 50%;
  &:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
  }
}
